import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_UNIVERSITY_DROPDOWN_REQUEST =
  "GET_UNIVERSITY_DROPDOWN_REQUEST";
export const GET_UNIVERSITY_DROPDOWN_SUCCESS =
  "GET_UNIVERSITY_DROPDOWN_SUCCESS";
export const GET_UNIVERSITY_DROPDOWN_FAILURE =
  "GET_UNIVERSITY_DROPDOWN_FAILURE";

export const GET_DEGREE_DROPDOWN_REQUEST = "GET_DEGREE_DROPDOWN_REQUEST";
export const GET_DEGREE_DROPDOWN_SUCCESS = "GET_DEGREE_DROPDOWN_SUCCESS";
export const GET_DEGREE_DROPDOWN_FAILURE = "GET_DEGREE_DROPDOWN_FAILURE";

export const GET_YEAR_DROPDOWN_REQUEST = "GET_YEAR_DROPDOWN_REQUEST";
export const GET_YEAR_DROPDOWN_SUCCESS = "GET_YEAR_DROPDOWN_SUCCESS";
export const GET_YEAR_DROPDOWN_FAILURE = "GET_YEAR_DROPDOWN_FAILURE";

export const getUniversitiesDropdown = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/university/list/v1`;
      const response = await axios.get(url);
      dispatch({
        type: GET_UNIVERSITY_DROPDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIVERSITY_DROPDOWN_FAILURE,
        payload: error.message,
      });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getUniversityDegreeDropdown = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEGREE_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/degree/list/${id}/v1`;
      const response = await axios.get(url);
      dispatch({
        type: GET_DEGREE_DROPDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DEGREE_DROPDOWN_FAILURE,
        payload: error.message,
      });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getUniversityYearDropdown = (id, degreeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_YEAR_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/universityYear/list/${id}/${degreeId}/v1`;
      const response = await axios.get(url);
      dispatch({
        type: GET_YEAR_DROPDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_YEAR_DROPDOWN_FAILURE,
        payload: error.message,
      });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
