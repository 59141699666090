import { combineReducers } from "redux";
import * as userReducers from "./user/userReducer";
import * as blogReducer from "./blog/blogReducer";
import * as subjectReducer from "./subject/subjectReducer";
import * as enquireReducer from "./enquire/enquireReducer";
import * as topicReducer from "./topic/topicReducer";
import * as notesReducer from "./notes/notesReducer";
import * as homeReducer from "./home/homeReducer";
import * as planReducer from "./plan/planReducer";
import * as subscriberReducer from "./subscriber/subscriberReducer";
import * as dropdownReducer from "./dropdown/dropdownReducer";

export const rootReducer = combineReducers({
  [userReducers.userFeatureKey]: userReducers.reducer,
  [blogReducer.blogFeatureKey]: blogReducer.reducer,
  [subjectReducer.subjectFeatureKey]: subjectReducer.reducer,
  [enquireReducer.enquireFeatureKey]: enquireReducer.reducer,
  [topicReducer.topicFeatureKey]: topicReducer.reducer,
  [notesReducer.notesFeatureKey]: notesReducer.reducer,
  [homeReducer.homeFeatureKey]: homeReducer.reducer,
  [planReducer.planFeatureKey]: planReducer.reducer,
  [subscriberReducer.subscriberFeatureKey]: subscriberReducer.reducer,
  [dropdownReducer.dropdownFeatureKey]: dropdownReducer.reducer,
});
