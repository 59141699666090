import * as dropdownAction from "./dropdownAction";

export const dropdownFeatureKey = "dropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  universities: [],
  degree: [],
  years:[]
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case dropdownAction.GET_UNIVERSITY_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dropdownAction.GET_UNIVERSITY_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        universities: payload.data,
      };
    case dropdownAction.GET_UNIVERSITY_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //degree

    case dropdownAction.GET_DEGREE_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dropdownAction.GET_DEGREE_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        degree: payload.data,
      };
    case dropdownAction.GET_DEGREE_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // year

    case dropdownAction.GET_YEAR_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case dropdownAction.GET_YEAR_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        years: payload.data,
      };

    case dropdownAction.GET_YEAR_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
